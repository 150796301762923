import React from 'react'
import styled from 'styled-components'

// Master Components
import { BtnPink } from '../../../theme/Buttons/'

const ReachWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: transparent;
  margin-bottom: ${({ theme }) => theme.spacings.xLarge};
`

const ReachText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.desktopSizes.h5};
  margin-right: ${({ theme }) => theme.spacings.medium};
  margin-bottom: ${({ theme }) => theme.spacings.medium};
  margin-left: ${({ theme }) => theme.spacings.medium};
  text-align: center;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: ${({ theme }) => theme.spacings.small};
    max-width: ${({ theme }) => theme.breakpoints.md};
    padding-right: ${({ theme }) => theme.spacings.xxLarge};
    padding-left: ${({ theme }) => theme.spacings.xxLarge};
  }
`

const Reacher = () => {
  return (
    <>
      <ReachWrapper>
        <ReachText>
          Boss-up your web game through an intuitive, seamless, and stunning website. The Web
          Mobsters crew has got your back.
        </ReachText>
        <BtnPink href="contact" text="Reach out to us" />
      </ReachWrapper>
    </>
  )
}

export default Reacher
