import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BtnLink = styled(Link)`
  overflow: hidden;
  background: ${({ theme }) => theme.colors.pink};
  border-radius: ${({ theme }) => theme.spacings.xxSmall};
  padding-top: ${({ theme }) => theme.spacings.small};
  padding-right: ${({ theme }) => theme.spacings.medium};
  padding-bottom: ${({ theme }) => theme.spacings.small};
  padding-left: ${({ theme }) => theme.spacings.medium};
`

const BtnText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: relative;

  &:after {
    background: ${({ theme }) => theme.colors.white};
    content: '';
    height: 155px;
    left: -120%;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: ${({ theme }) => theme.animations.trans4};
    width: 50px;
    padding: 10px;
  }

  &:focus {
    color: ${({ theme }) => theme.colors.jet};
  }

  &:hover:after {
    left: 200%;
    transition: ${({ theme }) => theme.animations.trans4};
  }

  span {
    z-index: 2;
  }
`

const BtnPink = ({ href, text }) => {
  return (
    <>
      <BtnWrapper>
        <BtnLink to={`/${href}`}>
          <BtnText>
            <span>{text}</span>
          </BtnText>
        </BtnLink>
      </BtnWrapper>
    </>
  )
}

export default BtnPink
