import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'

const RightClick = ({ children, ...props }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            hiddenRoute
          }
        }
      }
    `,
  )

  const hiddenRoute = data.site.siteMetadata.hiddenRoute

  const click = e => {
    e.preventDefault()
    return navigate(`/${hiddenRoute}/`)
  }

  return (
    <>
      <div onContextMenu={click} aria-hidden="true">
        {children}
      </div>
    </>
  )
}

export default RightClick
