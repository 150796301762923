import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import Logo from './Logo'
import Resize from '../Resize'
import MobileNav from './MobileNav'
import DesktopNav from './DesktopNav'

const HeaderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacings.medium};
  /* padding-right: ${({ theme }) => theme.spacings.medium}; */
  /* padding-left: ${({ theme }) => theme.spacings.medium}; */
  top: 0;
  height: 88px;
  align-items: center;
  flex-wrap: nowrap;
  display: flex;
  justify-content: space-between;
`

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              href
            }
          }
        }
      }
    `,
  )

  const menu = data.site.siteMetadata.menuLinks

  return (
    <>
      <HeaderWrapper>
        <Logo />
        <Resize mobile={<MobileNav menu={menu} />} desktop={<DesktopNav menu={menu} />} />
      </HeaderWrapper>
    </>
  )
}

export default Header
