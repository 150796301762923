import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// React Icons
import { FaBars, FaTimes } from 'react-icons/fa'

const Icon = styled.div`
  overflow: hidden;
  z-index: 50;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  z-index: 100;

  color: ${props => (props.visible ? 'white' : `${({ theme }) => theme.colors.eerie}`)};

  &:hover {
    color: 'white';
    transition: ${({ theme }) => theme.animations.trans1};
  }

  &:focus {
    color: ${props =>
      props.visible
        ? `${({ theme }) => theme.colors.white}`
        : `${({ theme }) => theme.colors.eerie}`};
  }
`

const NavOverflow = styled.div`
  position: fixed;
  overflow: hidden;
  top: 0;
  z-index: 50;
  top: 0;
  right: ${props => (props.visible ? `0` : '-100%')};
  width: 100%;
  height: 100vh;
`

const NavBlock = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: ${props => (props.visible ? `#2b096b` : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
`

const NavLinks = styled.ul`
  display: flex;
  flex-direction: column;
`

const NavHome = styled.li`
  width: 100%;
  display: flex;
  opacity: 1;
`

const NavItem = styled.li`
  width: 100%;
  display: flex;
`

const NavLink = styled(Link)`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.silver};
  font-size: ${({ theme }) => theme.mobileSizes.h5};
  padding: ${({ theme }) => theme.spacings.small};

  &:focus {
    color: ${({ theme }) => theme.colors.silver};
  }

  &:hover {
    transform: scale(1.2);
    transition: ${({ theme }) => theme.animations.trans2};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
    text-decoration-thickness: ${({ theme }) => theme.spacings.xxSmall};
    text-underline-offset: ${({ theme }) => theme.spacings.xSmall};
    text-decoration-color: ${({ theme }) => theme.colors.pink};
  }
`

const MobileNav = ({ menu }) => {
  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  return (
    <>
      <Icon visible={click} onClick={handleClick}>
        {click ? <FaTimes /> : <FaBars />}
      </Icon>
      <NavOverflow visible={click}>
        <NavBlock visible={click} onClick={handleClick}>
          <NavLinks onClick={handleClick}>
            <NavHome>
              <NavLink to="/">Home</NavLink>
            </NavHome>
            {menu.map((item, key) => {
              return (
                <NavItem key={key} visible={click} iter={key}>
                  <NavLink to={`/${item.href}`}>{item.name}</NavLink>
                </NavItem>
              )
            })}
          </NavLinks>
        </NavBlock>
      </NavOverflow>
    </>
  )
}

export default MobileNav
