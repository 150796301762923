import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const MoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 0;

  a {
    transition: color 0.3s;
  }

  a:after {
    top: -2px;
    left: -2px;
    padding: 2px;
    z-index: -1;
    background: ${({ theme }) => theme.colors.pink};
    transition: transform 0.2s, opacity 0.3s;
  }

  a:hover,
  a:hover svg {
    color: ${({ theme }) => theme.colors.white};
    fill: ${({ theme }) => theme.colors.white};
  }

  a:after {
    transform: scale(1.3);
    opacity: 0;
  }

  a:hover:after {
    transform: scale(1);
    opacity: 1;
  }
`

const BtnLink = styled(Link)`
  padding: ${({ theme }) => theme.spacings.xxSmall};
  border-radius: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
  color: ${({ theme }) => theme.colors.spanish};
  border: 2px solid ${({ theme }) => theme.colors.silver};

  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
  }

  &:before {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    display: block;
    -webkit-font-smoothing: antialiased;
  }
`

const ArrowIcon = styled.svg`
  display: flex;
  align-items: center;
  justify-content: center;
  fill: ${({ theme }) => theme.colors.spanish};
  height: ${({ theme }) => theme.mobileSizes.h5};
  line-height: 0;
  margin: 0;
`

const BtnArrow = ({ path }) => {
  return (
    <>
      <MoreBtn>
        <BtnLink to={path}>
          <ArrowIcon x="0px" y="0px" viewBox="0 0 24 24">
            <path
              d="M13.5,16.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l3.5-3.5L13,8.5c-0.3-0.3-0.3-0.8,0-1.1
			s0.8-0.3,1.1,0l4,4c0.3,0.3,0.3,0.8,0,1.1l-4,4C13.9,16.7,13.7,16.8,13.5,16.8z"
            />
            <path
              d="M6.5,17.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1l4.5-4.5L6,7.5C5.7,7.2,5.7,6.8,6,6.5s0.8-0.3,1.1,0
			l5,5c0.3,0.3,0.3,0.8,0,1.1l-5,5C6.9,17.7,6.7,17.8,6.5,17.8z"
            />
            <path fill="none" d="M0,0h24v24H0V0z" />
          </ArrowIcon>
        </BtnLink>
      </MoreBtn>
    </>
  )
}

export default BtnArrow
