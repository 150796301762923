import React from 'react'
import styled from 'styled-components'

// React Icons
import { FaGithub } from 'react-icons/fa'

const GithubWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => theme.spacings.small};
`

const GitLink = styled.a`
  color: ${({ theme }) => theme.colors.davys};
  transition: ${({ theme }) => theme.animations.trans1};

  &:hover {
    color: ${({ theme }) => theme.colors.spanish};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.davys};
  }
`

const Github = () => {
  return (
    <GithubWrapper>
      <GitLink href="https://github.com/webmobsters">
        <FaGithub />
      </GitLink>
    </GithubWrapper>
  )
}

export default Github
