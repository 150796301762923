import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components
import Reacher from './Reacher'
import ScrollTop from './ScrollTop'
import Github from './Github'

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const FooterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.eerie};
  padding-top: ${({ theme }) => theme.spacings.medium};
  padding-bottom: ${({ theme }) => theme.spacings.medium};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const FooterContents = styled.div`
  display: inline-flex;
  flex-direction: row;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const FooterCopy = styled.div`
  color: ${({ theme }) => theme.colors.davys};
  font-size: ${({ theme }) => theme.desktopSizes.small};
  margin-right: ${({ theme }) => theme.spacings.xxSmall};
  margin-left: ${({ theme }) => theme.spacings.xxSmall};
`

const FooterRights = styled.div`
  color: ${({ theme }) => theme.colors.davys};
  font-size: ${({ theme }) => theme.desktopSizes.small};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    margin-bottom: ${({ theme }) => theme.spacings.xxSmall};
  }
`

const FooterPrivacy = styled.div`
  color: ${({ theme }) => theme.colors.spanish};
  font-size: ${({ theme }) => theme.desktopSizes.small};
  margin-right: ${({ theme }) => theme.spacings.xxSmall};
  margin-left: ${({ theme }) => theme.spacings.xxSmall};
`

const PrivacyLink = styled(Link)`
  color: ${({ theme }) => theme.colors.davys};
  transition: ${({ theme }) => theme.animations.trans1};

  &:hover {
    color: ${({ theme }) => theme.colors.spanish};
  }

  &:focus {
    color: ${({ theme }) => theme.colors.davys};
  }
`

const Footer = () => {
  const year = new Date()

  return (
    <FooterContainer id="footerContainer">
      <Reacher />
      <FooterWrapper>
        <FooterContents>
          <FooterCopy>Copyright &#169; {year.getFullYear()} Web Mobsters, LLC.</FooterCopy>
          <FooterRights>All Rights Reserved.</FooterRights>
          <FooterPrivacy>
            <PrivacyLink to="/privacy">PRIVACY POLICY</PrivacyLink>
          </FooterPrivacy>
        </FooterContents>
        <Github />
      </FooterWrapper>
      <ScrollTop />
    </FooterContainer>
  )
}

export default Footer
