import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import useWindowSize from '../../utils/windowSize'

const ScrollBtn = styled.button`
  cursor: pointer;
  background: none;
  position: fixed;
  z-index: 1000;
  height: ${({ theme }) => theme.spacings.xLarge};
  bottom: ${({ theme }) => theme.spacings.small};
  right: ${({ theme }) => theme.spacings.small};

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
    visibility: hidden;
  }

  svg {
    height: 100%;
  }

  svg .background {
    fill: ${({ theme }) => theme.colors.pink};
  }

  svg .arrow {
    fill: ${({ theme }) => theme.colors.white};
  }
`

const ScrollTop = () => {
  const { height } = useWindowSize()
  const [scrollTop, setScrollTop] = useState(0)
  useEffect(() => {
    const onScroll = () => setScrollTop(window.pageYOffset <= 0 ? 0 : window.pageYOffset)
    window.addEventListener('scroll', onScroll)
  }, [scrollTop])

  if (height.inner * 1.5 <= scrollTop) {
    return (
      <ScrollBtn onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
        <svg x="0px" y="0px" viewBox="0 0 24 24">
          <g>
            <path
              className="background"
              d="M15.9,21.8h-8c-3.2,0-5.8-2.6-5.8-5.8V8c0-3.2,2.6-5.8,5.8-5.8h8c3.2,0,5.8,2.6,5.8,5.8v8
		C21.7,19.2,19.1,21.8,15.9,21.8z"
            />
            <path
              className="arrow"
              d="M9,16.5c-0.3,0-0.6-0.1-0.8-0.2c-0.8-0.5-1.2-1.5-0.7-2.4l3-5.5c0.2-0.3,0.4-0.5,0.7-0.7
		c0.4-0.2,0.9-0.3,1.3-0.1c0.4,0.1,0.8,0.4,1,0.8l3,5.5c0.3,0.5,0.3,1,0.1,1.5c-0.4,0.9-1.4,1.3-2.3,1l-2.2-0.9c-0.1,0-0.1,0-0.2,0
		l-2.2,0.9C9.5,16.5,9.2,16.5,9,16.5z M12,9c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1l-3,5.5c-0.1,0.1,0,0.3,0.1,0.3c0.1,0,0.1,0,0.2,0
		l2.2-0.9c0.4-0.2,0.9-0.2,1.3,0c0,0,0,0,0,0l2.2,0.9c0.1,0.1,0.3,0,0.3-0.1c0-0.1,0-0.1,0-0.2l-3-5.5C12.2,9.1,12.1,9,12,9
		C12.1,9,12,9,12,9z"
            />
          </g>
        </svg>
      </ScrollBtn>
    )
  } else {
    return ''
  }
}

export default ScrollTop
