import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const Nav = styled.nav`
  margin-top: 0.5rem;
  display: flex;
`

const NavList = styled.ul`
  display: inline-block;
`

const ListItem = styled.li`
  font-weight: 300;
  list-style-type: none;
  display: inline;
  margin: 0 1em;
  text-transform: uppercase;

  .active {
    color: ${({ theme }) => theme.colors.platinum};
    opacity: 1;
  }
`

const Ref = styled(Link)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.silver};
  opacity: 0.7;
  font-size: ${({ theme }) => theme.desktopSizes.p};
  position: relative;
  white-space: nowrap;

  &:before,
  &:after {
    position: absolute;
    width: 100%;
    height: 1.5px;
    background: ${({ theme }) => theme.colors.pink};
    top: 100%;
    left: 0;
    pointer-events: none;
    opacity: 1;
  }

  &:before {
    content: '';
    transform-origin: 50% 100%;
    transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0 100%,
      0 0,
      100% 0,
      100% 100%,
      0 100%,
      0 100%,
      100% 100%,
      100% 0%
    );
  }

  &:hover:before {
    transform: translate3d(0, 4px, 0) scale3d(1.08, 3, 1);
    clip-path: polygon(
      0% 0%,
      0% 100%,
      50% 100%,
      50% 0,
      50% 0,
      50% 100%,
      50% 100%,
      0 100%,
      100% 100%,
      100% 0%
    );
  }

  &:hover {
    transition: ${({ theme }) => theme.animations.trans1};
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }

  span {
    display: inline-block;
    transition: transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  }

  &:hover span {
    transform: translate3d(0, -2px, 0);
  }
`

const DesktopNav = ({ menu }) => {
  return (
    <>
      <Nav>
        <NavList>
          <ListItem>
            <Ref to={`/`} activeClassName={'active'}>
              <span>Home</span>
            </Ref>
          </ListItem>
          {menu.map((item, key) => {
            return (
              <ListItem key={key}>
                <Ref to={`/${item.href}`} activeClassName={'active'}>
                  <span>{item.name}</span>
                </Ref>
              </ListItem>
            )
          })}
        </NavList>
      </Nav>
    </>
  )
}

export default DesktopNav
