import { useLayoutEffect, useState } from 'react'

const useWindowSize = () => {
  const [size, setSize] = useState({
    height: { inner: 0, outer: 0 },
    width: { inner: 0, outer: 0 },
  })
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize({
        height: {
          inner: window.innerHeight,
          outer: window.outerHeight,
        },
        width: {
          inner: window.innerWidth,
          outer: window.outerWidth,
        },
      })
    }

    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size
}

export default useWindowSize
