import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

// Components
import RightClick from '../RightClick'

const LogoWrapper = styled.div`
  height: ${({ theme }) => theme.spacings.large};
  z-index: 100;
`

const LogoLink = styled(Link)`
  display: inline-flex;
`

const LogoSVG = styled.svg`
  height: ${({ theme }) => theme.spacings.large};
  fill: ${({ theme }) => theme.colors.white};
`

const LogoText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Triplepass';
  font-size: ${({ theme }) => theme.desktopSizes.h4};
  letter-spacing: 1px;
  margin-left: ${({ theme }) => theme.spacings.xSmall};
  display: flex;
  align-items: center;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
    visibility: hidden;
  }
`

const Logo = () => {
  return (
    <>
      <LogoWrapper>
        <RightClick>
          <LogoLink to={'/'}>
            <LogoSVG viewBox="0 0 500 500">
              <g>
                <path
                  d="M250,347.3c-19.2,0-34.8,4.3-34.8,5.2c0,1,0,0.2,0.1,0.2c8.9-0.7,21.2-1.9,34.7-1.9s25.9,1.1,34.7,1.9
		c0.1-0.1,0.1,0.8,0.1-0.2C284.8,351.6,269.2,347.3,250,347.3z"
                />
                <path
                  d="M395.5,134.8c-5.9-17.9-7-86.1-35.1-113.9c-35.5-35-84.6,16.5-108.6,15C205.5,33.1,177.5-7.3,144,22.4
		c-19.4,17.2-21.4,67.2-25,89c56.9,20.6,134.9,25.3,191.4,13.7c-8.7,3.9-25.1,11.5-52.2,16.2c21.2,2.4,33.9,3.7,37.8,3.3
		c-75.1,22.2-189.7-10.1-189.7-10.1C90.2,136.9-0.9,155.7,5.3,181.7C9.5,199.5,47.9,210.4,64,215c1.8,21.6,8.4,42.9,19.8,56.6
		c24,29,25,60.2,21.4,86.8l0,0.1c-1.3,9.5-3.3,18.8-5.3,28.2l-0.1,0.4c9.5-3.9,18.6-17,19.9-18.3c-1.7,26.2,0.1,41.5,23,55.8
		c-2.2-6.9-8.3-23.9-2-31c7.2,43.5,24.5,60.2,53.7,70.9c-7.1-8.4-11.2-17.3-11.8-23.5c23.9,33.3,47.6,29.6,67.8,50.3
		c20.1-20.7,43.9-17,67.8-50.3c-0.7,6.2-4.7,15.1-11.8,23.5c29.2-10.7,46.5-27.4,53.7-70.9c6.3,7.1,0.2,24.1-2,31
		c23-14.3,24.7-29.6,23-55.8c1.2,1.3,10.3,14.4,19.9,18.3l-0.1-0.4c-1.9-8.8-3.8-17.6-5.1-26.6l-0.1-0.8h0l-0.1-0.9
		c-3.7-26.5-2.6-57.8,21.4-86.8c11.6-14,18.2-35.7,19.9-57.6c14.5-3.8,46.3-13.2,55.1-26.4C514.3,154,412.1,139.9,395.5,134.8z
		 M117.7,272.5c-2.4,6.8-22.4-21.2-26-34.7c-3.6-5.8-5.6-11.8-6.5-17.5c3.8,0.8,7.7,1.6,11.8,2.4c1.4,11.7,6.7,24.7,13.2,29
		c-1.9-3.1-8.2-10.9-4.7-16.3C113.9,242.4,121.8,274,117.7,272.5z M295.6,349.4c-7.6,23.4-19.2,27.9-25.9,30.9c-2.2,1-3.4,1.4-5.7,1
		c-2.8-0.5-4.7-7.1-5.3-14.9c6-1.5,10.2-4,10.2-4.7c0-1,0-0.2,0-0.2c-4.8,0.7-11.5,1.5-18.8,1.5c-7.4,0-14-0.8-18.8-1.5
		c0,0.1,0-0.8,0,0.2c0,0.7,4.3,3.3,10.6,4.8c-0.6,7.8-2.5,14.3-5.3,14.8c-2.3,0.4-3.5,0-5.7-1c-6.7-3-18.3-7.4-25.9-30.9
		c-0.5-1.5-4.1-1.2-8.8-4.7c0,0,4.2,0.8,11.2-0.5c17.1-2.5,29.7-13.2,42.2-7.5l0.4,0.2l0.4-0.2l0.4,0.2l0.4-0.2
		c12.5-5.7,25.1,5,42.2,7.5c7,1.3,11.2,0.5,11.2,0.5C299.7,348.2,296.1,347.9,295.6,349.4z M370,235.9c-11.7,16.3-18.9,28.3-20,37.5
		c-0.4,3.4-11.3,19.2-6.1,30.5c2.2-11.7,6.1-19.2,11.1-23.8c0,0-5,21.3-8.9,35.2c-6.7,10.7-17.8,14.4-28.6,14.7
		c2.4,1.8,7.1,3.1,10.9,4.1c-13.6,3.3-20.7-7.2-33.7-15c-14.7-8.8-32.5-7.1-36.8-6.5c-2.4,0.6-5,0.9-7.8,0.9c-2.8,0-5.5-0.3-7.9-1
		c0,0.1,0,0.2,0,0.2s-21-3.7-37.8,6.4c-13,7.8-20.1,18.3-33.7,15c3.8-1,8.5-2.3,10.9-4.1c-10.8-0.4-21.8-4.1-28.6-14.7
		c-3.9-13.8-8.9-35.2-8.9-35.2c5,4.7,10.3,13.8,12.5,25.5c5.2-11.3-7.1-28.8-7.5-32.2c-1.1-9.2-8.3-21.2-20-37.5
		c-4.4-4.1-7.2-6.8-9.5-9.4c31.4,4.5,68.9,7.2,113.2,7.8c14.7,0.2,14.7,0.2,33.2,0c39.9-0.7,79.6-4,114.5-9.3
		C378.2,228.2,375.3,231,370,235.9z M407.8,237.8c-3.6,13.5-23.6,41.5-26,34.7c-4.1,1.5,3.8-30.1,12.2-37.1
		c3.5,5.5-2.8,13.2-4.7,16.3c6.8-4.4,12.3-18.3,13.4-30.3c4-0.7,8-1.5,11.8-2.3C413.7,225.1,411.7,231.6,407.8,237.8z"
                />
              </g>
            </LogoSVG>
            <LogoText>Web Mobsters</LogoText>
          </LogoLink>
        </RightClick>
      </LogoWrapper>
    </>
  )
}

export default Logo
