import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

// Image
import logo from '../../static/og-image.png'

const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        description
        author
      }
    }
  }
`

const SEO = ({ title, description, url, author, image }) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const sm = data.site.siteMetadata

        const metaDescription = description || sm.description
        const metaTitle = title || sm.title
        const metaAuthor = author || sm.author
        const metaUrl = url || sm.url
        const metaImage = image === undefined ? logo : image
        const metaKeywords = [
          'web design',
          'web development',
          'brand development',
          'app design',
          'app development',
          'packaging',
          'ebook',
        ]

        if (process.env.NODE_ENV === 'development') {
          const seoObject = {
            Title: metaTitle,
            Description: metaDescription,
            Author: metaAuthor,
            URL: metaUrl,
            Image: metaImage,
            Keywords: metaKeywords,
          }
          console.log('SEO', seoObject)
        }

        return (
          <Helmet
            bodyAttributes={{
              id: 'top',
            }}
            title={`${title} | ${sm.title}`}
            meta={[
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: description,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:image`,
                content: metaImage,
              },
              {
                property: `og:url`,
                content: metaUrl,
              },
              {
                property: `twitter:card`,
                content: `summary_large_image`,
              },
              {
                property: `twitter:creator`,
                content: metaAuthor,
              },
              {
                property: `twitter:title`,
                content: metaTitle,
              },
              {
                property: `twitter:description`,
                content: metaDescription,
              },
              {
                property: `twitter:image`,
                content: metaImage,
              },
            ].concat(
              metaKeywords && metaKeywords.length > 0
                ? {
                    name: `keywords`,
                    content: metaKeywords.join(`, `),
                  }
                : [],
            )}
          />
        )
      }}
    />
  )
}

export default SEO
