import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const BtnWrapper = styled.div`
  display: flex;
`

const Btn = styled.button`
  display: flex;
  border: none;
  color: inherit;
  cursor: pointer;
  background: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme }) => theme.spacings.small};
  line-height: 0;
  padding: ${({ theme }) => theme.spacings.small};
  font-size: ${props =>
    props.small === true
      ? ({ theme }) => theme.desktopSizes.small
      : ({ theme }) => theme.desktopSizes.p};

  font-weight: 700;
  outline: none;
  position: relative;
  transition: all 0.3s;
  border-radius: ${({ theme }) => theme.spacings.medium};
  border: 2px solid ${({ theme }) => theme.colors.pink};
  color: ${({ theme }) => theme.colors.pink};

  &:hover {
    background: ${({ theme }) => theme.colors.pink};
    color: ${({ theme }) => theme.colors.white};
  }
`

const BtnOutline = ({ href, title, small }) => {
  return (
    <>
      <BtnWrapper>
        <Link to={`/${href}`}>
          <Btn small={small}>{title}</Btn>
        </Link>
      </BtnWrapper>
    </>
  )
}

export default BtnOutline
