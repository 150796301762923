import React, { useState, useEffect } from 'react'

const Resize = ({ value = '960', desktop, mobile }) => {
  const [showMobile, setShowMobile] = useState(true)
  const showType = () => (window.innerWidth <= value ? setShowMobile(true) : setShowMobile(false))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => showType(), [])
  if (typeof window !== 'undefined') window.addEventListener('resize', showType)
  return <>{showMobile === true ? mobile : desktop}</>
}

export default Resize

// <Resize value={} mobile={} desktop={} />
